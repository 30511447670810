// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./SuggestedPrompts.css";

// import required modules
import { Navigation } from "swiper/modules";

const SuggestedPrompts = (props) => {
  const prompts = props?.prompts || [];
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      spaceBetween={10}
      slidesPerView={"auto"}
      className="custom-swiper"
      
    >
      {prompts.map((prompt, index) => {
        return (
          <SwiperSlide key={index}>
            <div onClick={() => props.onClick(prompt)} className="suggestion-button">{prompt}</div>
          </SwiperSlide>
        );
      })}
      
    </Swiper>
  );
};

export default SuggestedPrompts;
