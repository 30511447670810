import {useMemo, forwardRef} from "react";
import { Tooltip } from "@mui/material";
import "./FRAME.css";

const FRAME = forwardRef(({expand, showFRAME,
    propMargin, propBorderRadius, onClick, selectedAssistant, title}, ref) => {
    const selectedItem = selectedAssistant;
    if (selectedItem && selectedItem.appName) {
        selectedItem["name"] = selectedItem.appName;
    }
    if (selectedItem && selectedItem.appTitle) {
        selectedItem["title"] = selectedItem.appTitle;
    }

    const h2HeadingStyle = useMemo(() => {
        return {
            margin: propMargin,
            fontSize: "13px",
            // color: "#754dde",
            fontWeight: "normal",
            letterSpacing: "unset",
        };
    }, [propMargin]);

    const buttonStyle = useMemo(() => {
        return {
            borderRadius: propBorderRadius,
            border: "1px solid rgba(0, 0, 0, 0.1)",
        };
    }, [propBorderRadius]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        showFRAME && (
            <div className="f-r-a-m-e7">
                {
                    title && (<h2 className="h2-heading46" style={h2HeadingStyle}>
                        {title}
                    </h2>)
                }
                <Tooltip
                    key={"assistant-tooltip-large"}
                    title={"Select your assistant or create a new one"}
                    arrow
                >
                    <div onClick={onClick} ref={ref} className="button6 dropdown-button" style={buttonStyle}>
                        <div className="expand-instance">
                            <div className="addy-assistant5">
                                {
                                    selectedItem && selectedItem.name ?
                                        (selectedItem.name.length > 41 ?
                                            `${capitalizeFirstLetter(
                                                selectedItem.name.substring(
                                                    0, 39))}...` :
                                            capitalizeFirstLetter(selectedItem.name)) :
                                        "Loading..."
                                }

                            </div>
                            {selectedItem && selectedItem.title && (<div className="regular-addy-ai1">
                                {selectedItem && selectedItem.title ?
                                    selectedItem.title : "Loading..."}
                            </div>)}
                        </div>
                        <img className="expand-icon5" alt="" src={expand}/>
                    </div>
                </Tooltip>
                
            </div>
        )
    );
});

export default FRAME;
