const GlobalVariables = require("./globalVariables");

class User {
    static getEndpoints() {
        return {
            trainedAssistants: "/api/user/trained-assistants-new",
        };
    }

    constructor(uid, token, email) {
        this.uid = uid;
        this.token = token;
        this.email = email;
        this.assistants = []; // Initialize the assistants array in the state

        const defaultAssistants = [
            {
                "appID": "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d",
                "appImageURL": "",
                "appName": "Addy AI",
                "appTitle": "Trained to answer mortgage lending questions",
                "appType": "default",
                "creatorName": "Addy AI",
                "description": "Trained to answer mortgage lending questions",
                "traningStatus": "complete",
                "organizationId": "",
                "organizationName": "Default",
                "backgroundResponse": false,
                "publicId": "1123cacb-2789-4531-936c-8c9b8c94b647", // Default public ID for Addy AI
            },
        ];
        this.defaultAssistants = defaultAssistants;
    }

    isValid() {
        return this.uid !== "" && this.token !== "";
    }

    async getAssistants() {
        const API_URL_STAUTS = GlobalVariables.getAPIURL() + User.getEndpoints().trainedAssistants;
        try {
            // eslint-disable-next-line max-len
            const response = await fetch(
              `${API_URL_STAUTS}?uid=${this.uid}&authEmail=${this.email}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${this.token}`,
                },
              }
            );
            const data = await response.json();
            // Combine default assistants with fetched assistants
            // this.assistants = this.defaultAssistants.concat(data.assistants);
            this.assistants = data.assistants;
            return this.assistants;
        } catch (error) {
            console.error("Error fetching assistants:", error);
            return this.defaultAssistants;
        }
    }

    async setDefaultAssistant(appID) {
        // Make a POST request to the API to set the default assistant
        const API_URL_STAUTS = "https://hey-addy.web.app";
        try {
            const response = await fetch(`${API_URL_STAUTS}/user/default-assistant`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.token}`,
                    },
                    body: JSON.stringify({
                        uid: this.uid,
                        authEmail: this.email,
                        appID: appID,
                    }),
                });
            const data = await response.json();
            console.log("Default assistant set:", data);
            return data;
        } catch (error) {
            console.error("Error setting default assistant:", error);
            return false;
        }
    }
}

module.exports = User;
