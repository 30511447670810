import styles from "./ChatHistoryDropdown.module.css";
import {useEffect, useRef, forwardRef, useState} from "react";
import { Tooltip } from "@mui/material";
import moment from "moment";
import Assistant from "../models/assistantModel";

const ChatHistoryDropdown = forwardRef((props, ref) => {
    const dropdownRef = useRef(null);
    const displayPosition = props.displayPosition;
    const referencedElementId = props.referencedElementId;
    const today = moment();
    const weekStart = moment().startOf("week");
    const monthStart = moment().startOf("month");

    // Initialize the chatHistory state with an empty array
    const [chatHistory, setChatHistory] = useState([]);
    const [updatedPreviousChatNames, setUpdatedPreviousChatNames] = useState(false);

    const formatChatData = (data) => {
        return Object.keys(data).map((chatID) => {
            const chat = data[chatID];
            const lastActive = moment(chat.lastActive);
            let formattedDate;
            if (lastActive.isSame(today, "d")){
                formattedDate = "Today";
            } else if (lastActive.isBetween(weekStart, today)){
                formattedDate = "Last 7 days";
            } else if (lastActive.isBetween(monthStart, today)){
                formattedDate = lastActive.format("MMMM");
            } else {
                formattedDate = lastActive.format("YYYY");
            }
            // If there's chat clientHostname, replace www. with an empty string
            // If no clientHostname, return empty string
            const clientHostname = chat.clientHostname ? chat.clientHostname.replace("www.", "") : "unknown site";
            return {...chat, 
                    lastActive: formattedDate,
                    clientHostname: clientHostname,
                    originalLastActive: chat.lastActive}; // Keep raw date for sorting
        }).sort((a, b) => new Date(b.originalLastActive) - new Date(a.originalLastActive));
    };
    

    const initializeChatHistory = () => {
        // Fetch the saved chat ids from the local storage
        const sidebarChats = JSON.parse(localStorage.getItem("addy-ai-sidebar-chats"));
        if (!sidebarChats) {
            setChatHistory([]);
            return;
        }
        const chatIDs = Object.keys(sidebarChats);
        if (!(chatIDs && chatIDs.length)) {
            setChatHistory([]);
            return;
        }
        /**
         * Each sidebar chat is of the form:
         * {
         * chatID: "chatID",
         * chatName: "Chat Name",
         * clientHostname: "clientHostname", // Eg. www.google.com
         * appID: "appID",
         * dateStarted: "Date Started",
         * lastActive: "Last Active", // When the chat was last active
         */
        // Update the previous chat name once
        oneTimePreviousChatName(sidebarChats);
        const formattedChatHistory = formatChatData(sidebarChats);
        setChatHistory(formattedChatHistory);
    };

    const oneTimePreviousChatName = async (sidebarChats) => {
        if (updatedPreviousChatNames) {
            console.log("Previous chat names already updated");
            return;
        }

        const chatIDs = Object.keys(sidebarChats);
        const newSidebarChats = {...sidebarChats};
        let atLeaseOneUpdateWasMade = false;
        for (let i = 0; i < chatIDs.length; i++) {
            const chatID = chatIDs[i];
            const clientHostname = sidebarChats[chatID].clientHostname;
            const chatName = sidebarChats[chatID].chatName;
            if (!chatID || !clientHostname) {
                continue;
            }
            
            if (chatName && chatName !== clientHostname) {
                continue;
            }
            // chatName is missing or the same as clientHostname. Get the chat
            const chatHistory = JSON.parse(localStorage.getItem(chatID))
            if (!(chatHistory && chatHistory?.messages && chatHistory?.messages?.length)) {
                continue;
            }
            // Chat history exists. Get the first message
            const firstMessage = chatHistory.messages[0]?.message;
            if (!firstMessage) {
                continue;
            }
            const chatNameFromModel = await Assistant.getChatName(firstMessage);
            if (!chatNameFromModel) {
                continue;
            }
            // Update the newSidebarChats.chatID.chatName
            newSidebarChats[chatID].chatName = chatNameFromModel;
            atLeaseOneUpdateWasMade = true;
        }
        // Set the newSidebarChats to local storage addy-ai-sidebar-chats
        if (atLeaseOneUpdateWasMade) {
            localStorage.setItem("addy-ai-sidebar-chats", JSON.stringify(newSidebarChats));
        }
        setUpdatedPreviousChatNames(true);
    };

    // Call the displayDropdownUnder function when the component mounts
    useEffect(() => {
        initializeChatHistory();
        displayDropdownUnder();
    }, []);

    const onChatTitleClick = (chatID) => {
        // Call the parent component's function to open the chat
        props.onChatTitleClick(chatID);
    };

    const onCreateNewChatClick = () => {
        // Call the parent component's function to create a new chat
        props.onCreateNewChatClick();
    };

    const renderChatHistory = () => {
        const dates = [...new Set(chatHistory.map((chat) => chat.lastActive))];

        if (chatHistory.length === 0) {
            return (<div className={styles.noChats}>
                No chats yet.
            </div>);
        }

        const chatHistoryList = () => {
            return dates.map((date) => (
                <div key={date}>
                    <div className={styles.sectionTitle} style={{
                        paddingLeft: "8px",
                    }}>{date}</div>
                    {chatHistory.filter((chat) =>
                        chat.lastActive === date).map((chat) => (
                        <Tooltip
                            key={chat.chatID}
                            title={chat.chatName ? chat.chatName : `${chat.lastActive} - ${chat.clientHostname}`}
                            arrow={false}>

                        <div onClick={() => onChatTitleClick(chat.chatID)}
                            className={styles.chatTitle}
                            key={chat.chatID}>
                            {/* {`${chat.lastActive} - ${chat.clientHostname}`} */}
                            {chat.chatName ? chat.chatName : `${chat.lastActive} - ${chat.clientHostname}`}
                            </div>

                        </Tooltip>
                    ))}
                </div>
            ));
        };

        return <div className={styles.recentChatDirectoryList}>
            <div className={styles.infoHelperText} style={{paddingLeft: "8px", paddingBottom: "8px"}}>
                Recent chats
            </div>
            {chatHistoryList()}
            {/* <br></br>
            <div className={styles.infoHelperText}>
                Your chats are only saved on this device.
            </div> */}
        </div>;
    };

    // Function to display dropdown under the referenced element
    const displayDropdownUnder = () => {
        // eslint-disable-next-line no-undef
        const refElement = document.getElementById(referencedElementId);
        const dropdown = dropdownRef.current;

        if (refElement && dropdown) {
            const rect = refElement.getBoundingClientRect();
            let top = rect.bottom;
            const right = 0;

            // Modify top value based on displayPosition
            if (displayPosition === "top") {
                top = rect.top - dropdown.offsetHeight;
            }

            dropdown.style.position = "absolute";
            dropdown.style.top = `${top + 5}px`;
            dropdown.style.right = `${right}px`;
        }
    };

    return (
      <div
        ref={dropdownRef}
        className={styles.assistants}
        style={{ overflowY: "auto" }}
      >
        <img className={styles.plusIcon} alt="" src="/plus.svg" />
        <div className={styles.frameParent} ref={ref}>
          <div className={styles.frameGroup}>
          
            <Tooltip
                key={"toggle-sidebar-1"}
                title={"Toggle sidebar"}
                arrow>
                <div className="drawer-button dropdown-button" onClick={props.toggleDrawer}>
                    <img width="20" height="20" alt="Toggle sidebar" src="/open-left-drawer.svg" />
                </div>
            </Tooltip>
                    
            <div className={styles.h2HeadingWrapper}>
              <div
                onClick={() => onCreateNewChatClick()}
                className={styles.h2Heading}
              >
                New chat &nbsp;&nbsp;+
              </div>
            </div>
            {/* <div className={styles.div}>+</div> */}
          </div>
          {renderChatHistory()}
        </div>
      </div>
    );
});

export default ChatHistoryDropdown;
