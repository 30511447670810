import { useState, useEffect, useRef } from "react";
import "./MessageText.css";
// import parse from "html-react-parser";
import { sanitize } from "dompurify";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";
import AddyWebSocket from "../models/addyWebSocket";
import { Tooltip } from "@mui/material";

// Get current window width
const windowWidth = window.innerWidth;

const MessageText = (props) => {
  // const [likeSrc, setLikeSrc] = useState("/thumb.svg");
  //   const [dislikeSrc, setDislikeSrc] = useState("/thumb-1.svg");
  const copyIconRef = useRef(null);
  const feedbackContainerRef = useRef(null);
  // const [messageText, setMessageText] = useState(props.message.message);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMoreSources, setShowMoreSources] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set up the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      // Remove the resize event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const message = props.message;
  const documents = props.documents;
  const clientID = props.clientID;
  const isLastMessage = props.isLastMessage;
  const isFirstMessage = props.isFirstMessage;

  // const handleLikeClick = () => {
  //     setLikeSrc("/thumbs-up-filled.png");
  //     setDislikeSrc("/thumb-1.svg");
  // };

  const handleDislikeClick = () => {
    setDislikeSrc("/thumbs-down-filled.png");
    // setLikeSrc("/thumb.svg");
  };

  const getMarkedDownText = (markdownText) => {
    const rawMarkup = marked(markdownText, { sanitize: true });

    // Here marked() may have converted \n into \\n
    // so we replace \\n with <br />
    const lineBreaksAdded = rawMarkup.replace(
      /\\n/g,
      "<br class='addy-line-break' />"
    );

    // Purify the raw markup to prevent XSS attacks
    const purifiedMarkup = sanitize(lineBreaksAdded);
    return { __html: purifiedMarkup };
  };

  const handleCopyClick = () => {
    // eslint-disable-next-line no-undef
    copyIconRef.current.src = "/check_stroke.svg";
    setTimeout(() => {
      copyIconRef.current.src = "/copy_stroke.svg";
    }, 5000);
    // Send copy action to client via WebSocket
    // Define the message as a JSON string because the
    // server expects a string.
    // Use JSON.parse to convert it back to an object on client side.
    // IMPORTANT: Please ensure that the message is a valid JSON string.
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
      copiedText,
      `${clientID}-webpage`,
      "copy",
      3
    );

    // Copy the text to the clipboard
    navigator.clipboard.writeText(copiedText).then(
      function () {
        console.log("Text copied to clipboard");
      },
      function (err) {
        console.error("Error copying text to clipboard: ", err);
      }
    );
  };

  const handleInsertClick = () => {
    console.log("Insert clicked");
    // Send insert action to client via WebSocket
    console.log("Insert clicked 2");
    const copiedText = message?.message?.replace(/\\n/g, "\n");
    AddyWebSocket.sendDataToWebsocket(
      copiedText,
      `${clientID}-webpage`,
      "insertTextInReplyEditor"
    );
  };

  const isValidURL = (str) => {
    try {
      new URL(str);
    } catch (_) {
      return false;
    }
    return true;
  };

  // Append 'http://' if protocol not present
  const appendHttp = (url) => {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "http://" + url;
    }
    return url;
  };

  const getFaviconURL = (websiteURL) => {
    if (!websiteURL) {
      return "";
    }
    try {
        const urlObject = new URL(websiteURL);
        // Fetch url
        return `https://www.google.com/s2/favicons?domain=${urlObject.hostname}`;
    } catch (_) {
      return "";
    }
    
    
  };

  const sourceComponent = (documentTitle, documentContent, referenceNumber) => {
    // Check if the document title is a URL or not
    let isDocumentTitleAURL = documentTitle ? true : false;
    if (documentTitle && !isValidURL(documentTitle)) {
      const testAppendProtocol = appendHttp(documentTitle);
      if (!isValidURL(testAppendProtocol)) {
        // Still not a url
        isDocumentTitleAURL = false;
      }
    }
    return (
      <div
        className="source-container"
        onClick={() => {
          isDocumentTitleAURL && window.open(documentTitle, "_blank");
        }}
      >
        <div className="source-container-header">
          {/* The favicon if available */}
          {windowWidth > 850 &&
            isDocumentTitleAURL &&
            getFaviconURL(documentTitle) && (
              <img
                className="source-website-favicon"
                src={getFaviconURL(documentTitle)}
                alt=""
              />
            )}

          {windowWidth > 850 && (
            <div className="source-container-link">{documentTitle}</div>
          )}

          {windowWidth > 850 && (
            <div className="source-delimiter-symbol">&nbsp;•&nbsp;</div>
          )}

          <div className="source-reference-number">{referenceNumber}</div>
        </div>

        {/* <div className="source-content-text">
            {documentContent}
        </div> */}
      </div>
    );
  };

  const sourceTooltipComponent = (context, referenceNumber) => {
    // Check if the document title is a URL or not
    const documentTitle = context?.title;

    let isDocumentTitleAURL = documentTitle ? true : false;
    if (documentTitle && !isValidURL(documentTitle)) {
      const testAppendProtocol = appendHttp(documentTitle);
      if (!isValidURL(testAppendProtocol)) {
        // Still not a url
        isDocumentTitleAURL = false;
      }
    }

    const formatTextWithLineBreaks = (text) => {
      if(!text){
        return "";
      }
      return text.split("\\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    }
    return (
      <div className="source-container-tooltip">
        <div className="source-container-header">
          {/* The favicon if available */}
          {isDocumentTitleAURL && getFaviconURL(documentTitle) && (
            <img
              className="source-website-favicon"
              src={getFaviconURL(documentTitle)}
              alt=""
            />
          )}

          {documentTitle && (
            <div
              className="source-container-link"
              style={{
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              {documentTitle}
            </div>
          )}

          {/* {documentTitle && <div className="source-delimiter-symbol" style={{
            color: "rgba(255, 255, 255, 1)"
          }}>&nbsp;•&nbsp;</div>}

          <div className="source-reference-number" style={{
            color: "rgba(255, 255, 255, 1)"
          }}>
          {referenceNumber}
          </div> */}
        </div>

        <div
          className="source-content-text-tooltip"
          style={{
            color: "rgba(255, 255, 255, 1)",
            marginTop: "8px",
          }}
        >
          {context.text ? formatTextWithLineBreaks(context.text) : formatTextWithLineBreaks(context)}
          <br></br>
          {context.fullText && (
            <Tooltip
              key={1}
              title={formatTextWithLineBreaks(context.fullText)}
              arrow
              slotProps={{
                tooltip: {
                  sx: {
                    color: "white",
                    borderWidth: "2px",
                    borderColor: "white",
                    backgroundColor: "black",
                    maxHeight: "100px",
                    scrollBehavior: "smooth",
                    overflowY: "auto",
                  },
                },
              }}
            >
              <div className="source-full-text-tooltip">Read more</div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const ComparisonCard = ({ title, text }) => {
    return (
      <div className="comparison-card">
        <h3>{title}</h3>
        <p>{text}</p>
        <div className="comparison-index">Source</div>
      </div>
    );
  };

  useEffect(() => {
    // console.log("i am here in feedback message", feedbackContainerRef.current);
    if (feedbackContainerRef.current && !isLastMessage) {
      //   console.log("i am here in feedback message 222");
      feedbackContainerRef.current.addEventListener("mouseenter", () => {
        // console.log("i am here in mouse enter");
        feedbackContainerRef.current.classList.add("show-message-actions");
      });
      feedbackContainerRef.current.addEventListener("mouseleave", () => {
        feedbackContainerRef.current.classList.remove("show-message-actions");
      });
    }
    return () => {
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.removeEventListener(
          "mouseenter",
          () => {}
        );
        feedbackContainerRef.current.removeEventListener(
          "mouseleave",
          () => {}
        );
      }
    };
  }, [isLastMessage]);

  return (
    <section
      className={`top-bar-new-message5 ${
        message.type == "user"
          ? "user-message"
          : message.type == "assistant"
          ? "assistant-message"
          : ""
      } ${isFirstMessage ? "first-message" : ""} ${
        isLastMessage ? "last-message" : ""
      }`}
      id={message.messageId ? message.messageId : uuidv4()}
    >
      <div className="text-field5">
        <div className="h-heading-group">
          <div className="scroll-bar5">
            <div className="image-placeholder1">
              <div className="wrapper-union">
                <img
                  className="union-icon"
                  loading="lazy"
                  alt={`${message.type}`}
                  src={`${message.imageURL}`}
                />
              </div>
            </div>

            <div className="frame-parent1">
              <div className="h2-heading-parent24">
                <b className="h2-heading49">
                  {message.name && message.name.length > 40
                    ? `${message.name.substring(0, 39)}...`
                    : message.name}
                </b>
                {message.type === "user" && message.selectedText && (
                  <div className="selected-text-parent">
                    <div className="selected-text-header-parent">
                      <div className="selected-text-header">
                        {message.selectedTextTitle || "Selected text"}
                      </div>
                    </div>
                    <div className="selected-text">
                      {message.selectedText.length > 250
                        ? message.selectedText.substring(0, 250) + "..."
                        : message.selectedText}
                    </div>
                  </div>
                )}

                {documents && documents.length > 0 && (
                  <div className="sources-parent-div">
                    <div className="sources-title">Sources</div>
                    <div className="sources-list addy-custom-scroll">
                      {documents.map((context, index) => (
                        <Tooltip
                          key={index}
                          title={sourceTooltipComponent(context, index + 1)}
                          arrow
                        >
                          {((index + 1 <= 3 || windowWidth <= 850) || showMoreSources) &&
                            sourceComponent(
                              context.title,
                              context.text,
                              index + 1
                            )}
                        </Tooltip>
                      ))}
                    </div>
                    {documents && documents.length > 3 && windowWidth > 850 && (
                      <div className="show-more-sources-text" onClick={() => {
                        setShowMoreSources(!showMoreSources);
                      }}>
                        {showMoreSources ? `Hide ${(documents.length) - 3} sources` : `Show ${(documents.length) - 3} more`}
                        </div>
                    )}
                  </div>
                )}

                <div className="h2-heading50">
                  <span>
                    {message.action == "thinking" && (
                      <div className="dot"></div>
                    )}

                    {/* eslint-disable-next-line max-len */}
                    {/* <div className={`model-response-text ${message.action == "thinking" ? "hide" : ""}`}>
                                            {parse(message.message)}
                                        </div> */}
                    <div
                      className={`model-response-text ${
                        message.action == "thinking" ? "hide" : ""
                      }`}
                      dangerouslySetInnerHTML={getMarkedDownText(
                        message.message
                      )}
                    />
                  </span>
                </div>
              </div>

              {message.type === "assistant" && (
                <div
                  ref={feedbackContainerRef}
                  className={`feedback-container frame-parent2 ${
                    message.action == "thinking" && !message?.message?.length
                      ? "hide"
                      : ""
                  } ${isLastMessage ? "show-message-actions" : ""}`}
                >
                  <div className="addy-copy-response-div">
                    <div
                      className="copy-response thumb-group"
                      onClick={handleInsertClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="insert"
                        src="/insert_stroke.svg"
                        data-toggle="tooltip"
                        title="Insert in email editor"
                      />
                      <div className="h2-heading52">Insert</div>
                    </div>

                    <div
                      className="copy-response thumb-group"
                      onClick={handleCopyClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="copy"
                        src="/copy_stroke.svg"
                        data-toggle="tooltip"
                        title="Copy to clipboard"
                        ref={copyIconRef}
                      />
                      <div className="h2-heading52">Copy</div>
                    </div>
                    <div
                      className="copy-response thumb-group"
                      onClick={props.onRephraseButtonClick}
                    >
                      <img
                        className="thumb-icon2"
                        alt="rephrase"
                        src="/rephrase.svg"
                        data-toggle="tooltip"
                        title="Rephrase the provided email"
                      />
                      <div className="h2-heading52">Rephrase</div>
                    </div>
                  </div>

                  <div className="addy-rate-response-div">
                    {/* <div className="thumb-parent">
                                            <img
                                                data-toggle="tooltip"
                                                title="Good response"
                                                className="like-thumb thumb-icon"
                                                loading="lazy"
                                                alt=""
                                                src={likeSrc}
                                                onClick={handleLikeClick}
                                            />
                                            <div className="h2-heading51"></div>
                                        </div> */}
                    {/* <div className="thumb-parent">
                      <img
                        data-toggle="tooltip"
                        title="Bad response"
                        className="dislike-thumb thumb-icon"
                        alt=""
                        src={dislikeSrc}
                        onClick={handleDislikeClick}
                      />
                      <div className="h2-heading52"></div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessageText;
